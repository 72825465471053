<template>
	<div class="full-height">
		<h6>{{ program.name }}</h6>
		<div
			class="pa-10 bg-white width-50"
		>
			<table class="table   td-left">
				<col width="120px">
				<tbody>
				<tr>
					<th>구분</th>
					<td>
						<select
							v-model="item.viewGroup"
							class="pa-10 "
							:disable="is_disable"
						>
							<option
								v-for="(distributor, index) in items_distributor"
								:key="'distributor_' + index"
								:value="distributor.code"
							>{{ distributor.name }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<th>제목</th>
					<td><input v-model="item.title" class="input-box pa-10" placeholder="제목을 입력하세요" maxlength="50" :disable="is_disable" /></td>
				</tr>
				<tr>
					<th>내용</th>
					<td>

						<editor
							v-if="item.content"
							height="350px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
							:initialValue="item.content"
							:disable="is_disable"
						/>
					</td>
				</tr>
				<tr>
					<th>노출 여부</th>
					<td>
						<button
							class="pa-5-10 "
							:class="item.exposure == '1' ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.exposure = '1'"
							:disable="is_disable"
						>노출</button>
						<button
							class="pa-5-10 mr-10"
							:class="item.exposure == '0' ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.exposure = '0'"
							:disable="is_disable"
						>숨김</button>
					</td>
				</tr>
				<tr>
					<th>상단 고정</th>
					<td>
						<button
							class="pa-5-10 "
							:class="item.fix == '1' ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.fix = '1'"
							:disable="is_disable"
						>고정</button>
						<button
							class="pa-5-10 mr-10"
							:class="item.fix == '0' ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.fix = '0'"
							:disable="is_disable"
						>미고정</button>

						<label>
							상단 고정 선택 시 최근 등록 순으로 정렬됩니다.
						</label>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>저장</button>
				<button
					class="bg-ddd pa-10-20"
					@click="toList"
				>목록</button>
			</div>
		</div>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';

export default {
	name: 'NoticeDetail'
	,props: ['Axios', 'user', 'codes', 'rules']
	,components: {Editor}
	,data: function(){
		return {
			program: {
				name: '공지사항 상세'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				title: ''
				, content: ''
				, hour: ''
				, min: ''
				, distributor: ''
				, is_use: 1
				, viewGroup: 0
			}
			,items_distributor: []
		}
	}
	,computed: {
		is_disable: function(){
			let is = false

				if(this.$route.name == 'NoticeBranch' && this.user.roleGroup != 'branch'){
					is = true
				}
			return is
		}
	}
	, methods: {
		save: async function(){
			try{
				this.$emit('onLoading')
				this.$set(this.item, 'updateNoticeIdx', this.item.idx)

				let content = this.$refs.content.invoke("getMarkdown")

				this.item.content = content

				const result = await this.Axios({
					method: 'put'
					,url: 'notice'
					,data: this.item
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'notice/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
				}else{
					if(result.message){
						this.$emit('setNotify', { type: 'error', message: result.message })
						this.$router.back()
					}else{
						this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					}
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getDistributor: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'notice/getViewGroup'
					,data: {
						branchWriter: '1'
					}
				})
				if(result.success){
					this.items_distributor = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isCancel: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
		}
		,clear: function(){
			this.is_modal = false
		}
		,toList: function(){
			//this.$emit('push', {name: 'NoticeList', not_query: true})
			this.$router.back()
		}
	}
	, created() {
		if(!this.$route.params.idx){
			this.$emit('setNotify', { type: 'error', message: '잘못된 접근입니다'})
			this.$router.back()
			return
		}
		this.$emit('onLoad', this.program)
		this.getDistributor()
		this.getData()
	}
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>